import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Autocomplete,
  useJsApiLoader,
  MarkerClusterer
} from '@react-google-maps/api'
import * as Transition from '../Transition'
import * as Libs from 'libs'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import { Power3 } from 'gsap'

import { TimelineMax } from 'gsap'
let autocompleteData = null

export default props => {
  const { className, center, zoom = 13, lang, data, ...rest } = props

  const containerStyle = {
    width: '100%',
    height: '100%'
  }
  const [currentLocation, setCurrentLocation] = useState({
    lat: 1,
    lng: -73.96661146139454
  })

  /*const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAEOgd0OcpdyxJiNZZgIF-cGHAZShHNs3s',
    libraries: ['places'],
    language: lang
  })*/

  const [map, setMap] = useState(/** @type google.maps.Map */ null)

  const [activeMarker, setActiveMarker] = useState(null)
  const handleActiveMarker = (marker, event) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }
  useEffect(() => {
    return () => {
      //imgLoadedList = []

      setMap(null)
    }
  }, [])

  const MapStyles = [
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi',

      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ]
  const mapContCss = () => {
    return css`
      .gm-style-iw-a {
      }
      .gm-style-iw + div {
        display: none;
      }

      .gm-style-iw-c {
        position: absolute;
        box-sizing: border-box;
        overflow: scroll !important;
        top: 0;
        left: 0;
        transform: translate(-50%, -100%);
        box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.4);
        background-color: #fff;
        border-radius: 8px;

        button {
          display: none !important;
        }
        ${Libs.Styles.Mq.moreTab} {
        }
        ${Libs.Styles.Mq.lessPab} {
          max-width: 88vw !important;
          width: 88vw;
        }
      }

      .gm-style-iw-d {
        overflow: visible !important;
        max-height: auto !important;
      }
      .gm-style-iw-t {
        bottom: 40px !important;
      }
    `
  }
  const infoWindowHeaderCss = () => {
    return css`
      ${Libs.Styles.Mq.moreTab} {
        font-size: 22px;
        line-height: 22px;
        ${Libs.Styles.Font.Serif()};
      }
      ${Libs.Styles.Mq.lessPab} {
        font-size: 18px;
        line-height: 18px;
        ${Libs.Styles.Font.Serif()};
      }
    `
  }

  const currentBtnCss = () => {
    return css`
      position: absolute;
      z-index: 1;
      background-color: #fff;
      width: 50px;
      padding: 6px;
      border-radius: 25px;
      right: 10px;
      top: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    `
  }

  const infoContCss = () => {
    return css`
      h2 {
        width: 80%;
      }

      max-width: 350px !important;
      max-height: auto !important;
      min-width: 300px !important;
      display: grid;
      row-gap: 1rem;
      overflow-y: scroll;
      ${Libs.Styles.Mq.moreTab} {
        padding: 8px 20px 20px 8px !important;
      }
      ${Libs.Styles.Mq.lessPab} {
        min-width: auto !important;
        padding: 8px 8px 8px 8px !important;
      }
    `
  }
  const mapInfoCss = () => {
    return css`
      > div {
        > * {
          margin-top: 10px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }

      h2 {
        ${Libs.Styles.Font.Serif()};
        ${Libs.Common.Func.getPcSpVwValue('font-size', 18, 16, true, 10, 1)};
        ${Libs.Common.Func.getPcSpVwValue('line-height', 18, 16, true, 10, 1)};
        letter-spacing: -0.5px;
      }
      h3 {
        ${Libs.Common.Func.getPcSpVwValue('font-size', 15, 14, true, 10, 1)};
        ${Libs.Common.Func.getPcSpVwValue('line-height', 15, 14, true, 10, 1)};
      }
    `
  }

  const postItemContCss = () => {
    return css`
      display: block;
      padding-top: 0.2rem;
    `
  }
  const postGridCss = () => {
    return css`
      width: 100%;
      display: grid;
      column-gap: 1rem;
      grid-template-columns: 2fr 90px;
      padding-right: 0px;

      .img-cont {
        grid-column: 2 / 3;
      }
      .info-p {
        grid-column: 1 / 2;
        font-size: 15px !important;
        line-height: 20px !important;
      }

      .link-cont {
        grid-column: 1 / -1;
      }
      .gatsby-image-wrapper {
        height: 90px;
      }
      ${Libs.Styles.Mq.lessPab} {
        column-gap: 0.5rem;
        grid-template-columns: auto 90px;
        img {
          height: 90px;
        }
      }
    `
  }
  const searchBoxCss = () => {
    return css`
      position: absolute;

      ${Libs.Styles.Mq.moreTab} {
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
      }
      ${Libs.Styles.Mq.lessPab} {
        width: calc(92vw - 50px);
        top: 12px;
        left: 12px;
      }
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
      box-sizing: border-box;
      width: 392px;
      height: 48px;
      padding: 0px 12px;
      border-radius: 8px 8px 0 0;
      border-radius: 8px;
      border: none;
      border-bottom: 1px solid rgb(218, 220, 224);
      input {
        font-size: 16px;
        line-height: 16px;
        outline: none;
        border: none;
        width: calc(100% - 30px);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .search-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        pointer-event: auto;
      }
    `
  }

  const mapCss = () => {
    return css`
      height: 50vh;
    `
  }
  const tl = new TimelineMax()
  if (map) {
    var latlng = map.getCenter()
    var lat = latlng.lat()
    var lng = latlng.lng()
    if (lat !== center.lat) {
      center.lat = lat
    }
    if (lng !== center.lng) {
      center.lng = lng
    }
    tl.to({}, 0.1, {}).call(function () {
      map.setZoom(map.getZoom())
    })
  }
  //if (!isLoaded) return 'Loading Maps'
  const onLoad = autocomplete => {
    autocompleteData = autocomplete
  }
  const onPlaceChanged = () => {
    if (autocompleteData !== null) {
      if (map) {
        map.panTo({
          lat: autocompleteData.getPlace().geometry.location.lat(),
          lng: autocompleteData.getPlace().geometry.location.lng()
        })
        map.setZoom(17)
      }
    } else {
      console.log('autocomplete: ', 'Autocomplete is not loaded yet!')
    }
  }

  return (
    <div className={className} {...rest}>
      <div className={'map-cont'} css={mapContCss}>
        <div
          onClick={() => {
            if (map) {
              map.panTo(currentLocation)
            }
          }}
          css={currentBtnCss}
        >
          <StaticImage src='../../images/icon-current.png' alt='icon me' />
        </div>
        <LoadScript
          googleMapsApiKey='AIzaSyAEOgd0OcpdyxJiNZZgIF-cGHAZShHNs3s'
          language={lang}
          libraries={['places']}
        >
          <GoogleMap
            css={mapCss}
            mapContainerStyle={containerStyle}
            center={center}
            onClick={e => {
              setActiveMarker(null)
            }}
            clickableIcons={false}
            zoom={zoom}
            gestureHandling={'greedy'}
            options={{
              styles: MapStyles,
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              zoomControl: false,
              gestureHandling: 'greedy',
              minZoom: 2,
              maxZoom: 20
            }}
            onLoad={map => {
              setMap(map)
              const setCurrentLocationFunc = setCurrentLocation
              if (navigator.geolocation) {
                navigator.geolocation.watchPosition(function (position) {
                  const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  }
                  setCurrentLocationFunc(pos)
                })
              }
            }}
            onZoomChanged={() => {
              if (map) {
                //console.log('AAA --- ', map.getZoom(), currentLocation)
              }
            }}
          >
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              {/*<Libs.Atoms.Input
              css={searchBoxCss}
              className='full-size'
              type='text'
              placeholder='Search map'
              name='address'
              label='address
'
            />*/}
              <div css={searchBoxCss}>
                <input type='text' placeholder='Search here' />
                <div className='search-btn'>
                  <Libs.Atoms.Icon type='search' />
                </div>
              </div>
            </Autocomplete>
            <MarkerClusterer
              averageCenter
              gridSize={30}
              maxZoom={17}
              minimumClusterSize={2}
            >
              {clusterer => (
                <>
                  <Marker
                    title={'me'}
                    name={'me'}
                    key={'me'}
                    position={currentLocation}
                    icon={{
                      url: `https://loworks-inc.heteml.net/nbktimes/images/icon-me.png`,
                      size: { width: 50, height: 50 },
                      anchor: { x: 12.5, y: 25 },

                      scaledSize: { width: 25, height: 25 }
                    }}
                  ></Marker>

                  {data.map((item, i) => {
                    const location = item.node.location
                    const len = item.node.content___post
                      ? item.node.content___post.length
                      : 0
                    let tag = null

                    for (let i = 0; i < len; i++) {
                      const post = item.node.content___post[i]
                      if (!post.tags) break
                      tag = post.tags.filter(tag => {
                        // オブジェクトを配列に変換
                        if (tag.group === 'Category') return true
                      })[0]
                      if (tag) break
                    }
                    /*const tag = item.node.content___post.filter(post => {
                      return post.tags.filter(tag => {
                        // オブジェクトを配列に変換
                        if (tag.group === 'Category') return true
                      })
                    })

                    const tag = item.node.content___post[0].tags.filter(tag => {
                      // オブジェクトを配列に変換
                      if (tag.group === 'Category') return true
                    })[0]*/
                    if (
                      ('item.node.content___post -- ', item.node.content___post)
                    )
                      if (
                        !item.node.content___post ||
                        !item.node.content___post[0]
                      )
                        return false
                    const iconSrc =
                      tag &&
                      (tag.slug === 'museum' || tag.slug === 'bagel-shop')
                        ? `${tag.slug}`
                        : item.node.content___post
                        ? `${item.node.content___post[0].category.slug}`
                        : null

                    const newPosts = item.node.content___post
                      ? item.node.content___post.concat()
                      : null

                    if (newPosts && newPosts.length > 1) {
                      newPosts.sort(function (a, b) {
                        a = new Date(a.date)
                        b = new Date(b.date)

                        return a > b ? -1 : a < b ? 1 : 0
                      })
                    }

                    if (location && iconSrc) {
                      return (
                        <>
                          <Marker
                            title={item.node.slug}
                            name={item.node.name}
                            clusterer={clusterer}
                            key={i}
                            icon={{
                              url: `https://loworks-inc.heteml.net/nbktimes/images/icon-${iconSrc}.png`,
                              size: { width: 50, height: 72 },
                              anchor: { x: 12.5, y: 36 },

                              scaledSize: { width: 25, height: 36 }
                            }}
                            onClick={event => handleActiveMarker(i, event)}
                            position={{ lat: location.lat, lng: location.lon }}
                          >
                            {activeMarker === i ? (
                              <InfoWindow
                                options={
                                  Libs.Common.Func.getDetailedDeviceSize() ===
                                  'lessPab'
                                    ? { maxWidth: '88vw' }
                                    : null
                                }
                                onDomReady={window => {
                                  const element = document.querySelector(
                                    '.gm-style-iw-c button'
                                  )

                                  if (element) element.remove()
                                  /*if (
                                    Libs.Common.Func.getDetailedDeviceSize() ===
                                    'lessPab'
                                  ) {
                                    const iwcEle =
                                      document.querySelector('.gm-style-iw-c')
                                    iwcEle.style.cssText = `background:#fff; max-width:88vw !important; width:88vw;`
                                  }*/
                                }}
                                onCloseClick={() => setActiveMarker(null)}
                              >
                                <div
                                  css={infoContCss}
                                  className={'info-window'}
                                >
                                  <Libs.Atoms.H2 css={infoWindowHeaderCss}>
                                    {data[i].node.label}
                                  </Libs.Atoms.H2>
                                  {newPosts.map((post, j) => {
                                    return (
                                      <>
                                        {j === 0 ? (
                                          <div className={'link-cont'}>
                                            {post.links.map((link, k) => {
                                              return (
                                                <a
                                                  className={'link-item'}
                                                  href={link.url}
                                                  target='_new'
                                                >
                                                  <Libs.Atoms.Icon
                                                    type={link.icon}
                                                  />
                                                </a>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <></>
                                        )}

                                        <Libs.Atoms.TriggerLink
                                          css={postItemContCss}
                                          to={`/${data[i].node.node_locale}/${post.category.slug}/${post.slug}/`}
                                        >
                                          <div css={postGridCss}>
                                            {post.mapInfo &&
                                            post.mapInfo[0].field ? (
                                              <Libs.Atoms.RichText
                                                css={mapInfoCss}
                                              >
                                                {post.mapInfo[0].field}
                                              </Libs.Atoms.RichText>
                                            ) : (
                                              <div>
                                                <Libs.Atoms.P
                                                  className={'info-p'}
                                                  styles={{
                                                    fontPc: {
                                                      fontSize: 14,
                                                      lineHeight: 20,
                                                      fontFace: 'serif'
                                                    },
                                                    fontSp: {
                                                      fontSize: 14,
                                                      lineHeight: 20,
                                                      fontFace: 'serif'
                                                    }
                                                  }}
                                                >
                                                  {post.title.length > 60
                                                    ? post.title.substr(
                                                        0,
                                                        60 - 1
                                                      ) + '…'
                                                    : post.title}
                                                </Libs.Atoms.P>
                                              </div>
                                            )}
                                            {post.thumbnail ? (
                                              <div
                                                className={
                                                  'img-cont img-element'
                                                }
                                              >
                                                <GatsbyImage
                                                  image={
                                                    post.thumbnail
                                                      .gatsbyImageData
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </Libs.Atoms.TriggerLink>
                                        <a
                                          className={'map-info-close-button'}
                                          onClick={() => setActiveMarker(null)}
                                        >
                                          <Libs.Atoms.Span
                                            styles={{
                                              fontPc: {
                                                fontSize: 14,
                                                fontFace: 'serif'
                                              }
                                            }}
                                          >
                                            Close
                                          </Libs.Atoms.Span>
                                        </a>
                                      </>
                                    )
                                  })}
                                </div>
                              </InfoWindow>
                            ) : null}
                          </Marker>
                        </>
                      )
                    }
                  })}
                </>
              )}
            </MarkerClusterer>
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}
